export const ISTOCHNIKI_ZATRAT_HANDBOOK_NAME = 'istochniki_zatrat';
export const NAPRAVLENIE_ZATRAT_HANDBOOK_NAME = 'napravlenie_zatrat';
export const TSELI_ZATRAT_HANDBOOK_NAME = 'tseli_zatrat';
export const TIP_DOGOVORA_HANDBOOK_NAME = 'tip_dogovora';
export const MONTHS_HANDBOOK_NAME = 'kalendar__po_mesyatsam';
export const YEARS_HANDBOOK_NAME = 'kalendar__po_godam';
export const FILIALS_HANDBOOK_NAME = 'filial_';

export const AnalyticsActions = {
	RowFilterChange: 'ROW_FILTER_CHANGE',
	RowFilterReset: 'ROW_FILTER_RESET',
	OpenSidePageWithContextMenu: 'OPEN_SIDE_PAGE_WITH_CONTEXT_MENU',
	DuplicateRowToOpenContextMenu: 'DUPLICATE_ROW_TO_OPEN_CONTEXT_MENU',
	DuplicateRowToApplyContextMenu: 'DUPLICATE_ROW_TO_APPLY_CONTEXT_MENU',
	DuplicateRowToSelected: 'DUPLICATE_ROW_TO_SELECTED',
	DuplicateRowBelowContextMenu: 'DUPLICATE_ROW_BELOW_CONTEXT_MENU',
	DuplicateRowBelowSelected: 'DUPLICATE_ROW_BELOW_SELECTED',
	CreateNewRowContextMenu: 'CREATE_NEW_ROW_CONTEXT_MENU',
	CopyRowLinkContextMenu: 'COPY_ROW_LINK_CONTEXT_MENU',
	CopyRowDataContextMenu: 'COPY_ROW_DATA_CONTEXT_MENU',
	CopyCellDataContextMenu: 'COPY_CELL_DATA_CONTEXT_MENU',
	RemoveCellDataContextMenu: 'REMOVE_CELL_DATA_CONTEXT_MENU',
	ExportTable: 'EXPORT_TABLE',
};

export type AnalyticsActions =
	(typeof AnalyticsActions)[keyof typeof AnalyticsActions];
