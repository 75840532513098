import { TableCell, TableRow } from '@eui/table';

import { BudgetTableCell, BudgetTableRow } from './table.type';

export interface BudgetTableContextMenu {
	scope: ContextMenuScope;
	row: TableRow<BudgetTableRow, BudgetTableCell>;
	cell: TableCell<BudgetTableCell>;
}

export const ContextMenuScope = {
	Row: 'row',
	Cell: 'cell',
} as const;

export type ContextMenuScope =
	(typeof ContextMenuScope)[keyof typeof ContextMenuScope];
