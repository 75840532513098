import { TableColumn } from '@eui/table';

const firstRowHeaders: TableColumn[] = [
	{
		id: 'type',
		property: 'type',
		title: 'Тип',
		fixed: true,
		resizable: false,
		rowspan: 2,
		minWidth: 120,
	},
	{
		id: 'final_budget',
		title: 'Итоговый бюджет',
		fixed: true,
		resizable: false,
		rowspan: 2,
		minWidth: 150,
	},
	{
		id: 'revenue',
		property: 'revenue',
		title: 'Валовая выручка',
		fixed: false,
		resizable: false,
		rowspan: 2,
		minWidth: 160,
		info: 'План валовой выручки вносится<br/>вручную в бюджетах',
	},
	{
		id: 'bm_revenue',
		property: 'bm_revenue',
		title: 'БМ от ВВ, %',
		fixed: false,
		resizable: false,
		rowspan: 2,
		minWidth: 100,
	},
	{
		id: 'lead_gen_group',
		title: 'Лидогенерация, в т.ч.субсидирование',
		resizable: false,
		fixed: false,
		minWidth: 500,
		colspan: 14,
	},
	{
		id: 'look_group',
		title: 'Имидж, в т.ч.PR и фирменный стиль',
		resizable: false,
		fixed: false,
		minWidth: 500,
		colspan: 8,
	},
	{
		id: 'other',
		title: 'Иное',
		resizable: true,
		fixed: false,
		rowspan: 2,
		minWidth: 120,
	},
	{
		id: 'ensuring_the_efficiency_of_marketing',
		title: 'Обеспечение работоспособности маркетинга',
		resizable: true,
		fixed: false,
		rowspan: 2,
		minWidth: 120,
	},
	{
		id: 'aggregators',
		title: 'Агрегаторы',
		resizable: true,
		fixed: false,
		rowspan: 2,
		minWidth: 120,
	},
];

const secondRowHeaders: TableColumn[] = [
	{
		id: 'final_lead_gen',
		title: 'Итог (Лидген)',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'new_buildings',
		title: 'Новостройки',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'secondary_real_estate',
		title: 'Вторичка',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'suburban_real_estate',
		title: 'Загородка',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'commercial_real_estate',
		title: 'Коммерческая',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'garages',
		title: 'Гаражи',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'mortgage',
		title: 'Ипотека',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'rent',
		title: 'Аренда',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'hr_lead_gen',
		title: 'HR',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'seo',
		title: 'SEO',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'net_subsidization_of_aggregators',
		title: 'Чистое субсидирование агрегаторов',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'subsidy_marketing_plan',
		title: 'Субсидия «Маркетинг-план»',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'subsidy_differentiated_pricing',
		title: 'Субсидия «диф. ценообразование»',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'other_lead_gen',
		title: 'Иное',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'final_look',
		title: 'Итог (Имидж)',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'royalty',
		title: '0,2% роялти',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'client_ytp',
		title: 'Клиентские УТП',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'frk',
		title: 'ФРК (не HR)',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'hr_look',
		title: 'HR',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'branded_products',
		title: 'Бренд. продукция',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'office_design',
		title: 'Оформление офиса',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
	{
		id: 'other_look',
		title: 'Иное',
		resizable: true,
		fixed: false,
		minWidth: 120,
	},
];

export const CITIES_TABLE_HEADERS: TableColumn[][] = [
	[
		{
			id: 'city',
			title: 'Город',
			fixed: true,
			resizable: false,
			rowspan: 2,
			minWidth: 180,
			expand: true,
		},
		...firstRowHeaders,
	],
	secondRowHeaders,
];

export const MONTHS_TABLE_HEADERS: TableColumn[][] = [
	[
		{
			id: 'month',
			title: 'Месяц',
			fixed: true,
			resizable: false,
			rowspan: 2,
			minWidth: 180,
			expand: true,
		},
		...firstRowHeaders,
	],
	secondRowHeaders,
];
